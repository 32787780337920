import { allowedColors } from '@glowforge/gf-styled/src/theme/constants/colors';
import { idConfig } from "../util/shared-configs";

export default {
  name: 'Glowforge:Section',
  layerName: 'Glowforge Section',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2F682efef23ace49afac61748dd305c70a',
  noWrap: true,
  inputs: [
    {
      name: 'maxWidth',
      type: 'number',
      defaultValue: 1200,
    },
    {
      name: 'lazyLoad',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      description: 'Only render this section when in view',
    },
    {
      name: 'backgroundColor',
      type: 'string',
      defaultValue: 'white',
      enum: allowedColors,
    },
    {
      name: 'childrenFullHeight',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      description:
      // eslint-disable-next-line max-len
        'Will give height 100% to builderblocks inside of section. Useful for advanced positioning.',
    },
    idConfig,
  ],
  defaultStyles: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '50px',
    paddingBottom: '50px',
    marginTop: '0px',
    width: '100%',
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'column',
    alignItems: 'center',
  },

  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      responsiveStyles: {
        large: {
          textAlign: 'left',
        },
      },
      component: {
        name: 'Glowforge:Text',
        options: {
          text: `I'm a section heading.
Replace me with something`,
          as: 'h2',
          variant: 'title',
        },
      },
    },
  ],
};
