export default {
    name: 'youtubePlaylist',
    friendlyName: 'Youtube Playlist',
    broadcast: true,
    noWrap: true,
    defaultStyles: {},
    image:
        'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Ffa46476a97184eceb30610a19b6c1b35?quality=60&width=200&height=200',
    inputs: [
        {
            name: 'playlistId',
            friendlyName: 'Playlist Id',
            type: 'string',
        },
        {
            name: "isShorts",
            friendlyName: "Is Shorts",
            type: 'boolean',
            default: false
        },
        {
            name: 'title',
            type: 'string',
        },
        {
            name: 'btnText',
            type: 'string',
        },
        {
            name: 'videosLimit',
            type: 'number',
            defaultValue: 3
        }
    ],
};
