import styled from 'styled-components';
import {
    Paragraph,
    Heading
} from '@glowforge/gf-styled/src/components/typography';
import Button from '@glowforge/gf-styled/src/components/button';

const ContentContainer = styled('div')`
    width: 100%;
    @media screen and (max-width: 989px) {
        .video-skeleton-wrapper.mobile-only-one:not(:first-child) {
           display: none;
        }
    }
`
const VideosContainer = styled('div')`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${props => !props.isShorts ? '30' : '7'}px;
    margin: 0px auto;
    width: 100%;
    .placeholder-image{
        height: ${props => !props.isShorts ? '200' : '560'}px;
        width: ${props => !props.isShorts ? '300' : '315'}px;
        border-radius: 10px;
    }
`;

const VideoWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    max-width: ${props => !props.isShorts ? '300' : '315'}px;
    row-gap: 10px;
`;

const IframeVideoWrapper = styled('iframe')`
   border-radius: 10px;
   border: 0;
   ${props => props.isShorts && 'height:560px;width:315px;'}
`;

const VideoTitle = styled(Paragraph)`
    font-size: 14px;
`

const TitleBtnWrapper = styled('div')`
    width: 100%;
    display:flex;
    gap: 20px 30px;
    justify-content: space-between;
    margin-bottom: 20px;
`

const ContentTitle = styled(Heading)`
    font-size: 24px;
    color: #334b4f;
    font-family: 'Exo 2', sans-serif;
    letter-spacing: normal;
`

const ViewButton = styled(Button)`
    display: none;
    background: #1E93A5;
    border-radius: 10em;
    color: #FFFF;
    font-size: 14px;
    height: fit-content;
    line-height: 150%;
    padding: 4px 16px;
    width: fit-content;
    border: unset;
    transition: 0.5s cubic-bezier(0.37, 0.01, 0, 0.98);
    white-space: nowrap;
    cursor: pointer;
    font-family: 'Exo 2', sans-serif;
    &:hover {
        background: #26b8ce;
    }
    &.mobile-btn {
        @media screen and (max-width: 989px) {
            box-shadow: unset;
            margin-top: 10px;
            display: flex;
        }
    }
    &.desktop-btn {
    @media screen and (min-width: 990px) {
        display: flex;
    }
    
`

export {
    ContentContainer,
    VideosContainer,
    VideoWrapper,
    IframeVideoWrapper,
    VideoTitle,
    TitleBtnWrapper,
    ContentTitle,
    ViewButton
};
