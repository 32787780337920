import React, { useState, useEffect, useRef } from 'react';
import PlaceholderImage from '@glowforge/gf-styled/src/components/placeholder-image';
import useMediaQuery from '@glowforge/gf-styled/src/hooks/useMediaQuery';
import PropTypes from 'prop-types';
import { QUERIES } from '@glowforge/gf-styled/src/theme/constants';

import {
  ContentContainer,
  VideosContainer,
  VideoWrapper,
  IframeVideoWrapper,
  VideoTitle,
  TitleBtnWrapper,
  ContentTitle,
  ViewButton,
} from './youtube-playlist.styles';

const YoutubePlaylist = ({
  playlistId,
  videosLimit,
  isShorts,
  title,
  btnText,
}) => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [shouldLoad, setShouldLoad] = useState(false);
  const containerRef = useRef(null);
  const isDesktop = useMediaQuery(QUERIES.desktopAndUp);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShouldLoad(true);
          observer.disconnect();
        }
      },
      { threshold: 0 },
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => observer.disconnect();
  }, []);
  useEffect(() => {
    if (!shouldLoad) return;
    const fetchPlaylist = async () => {
      try {
        const response = await fetch(
          `/api/youtube-playlist?playlistId=${playlistId}&videosLimit=${
            isDesktop || isShorts ? videosLimit : 1
          }`,
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        data?.length > 0 ? setVideos(data) : setIsError(true);
      } catch (error) {
        setIsError(true);
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlaylist();
  }, [playlistId, videosLimit, shouldLoad]);
  return (
    !isError && (
      <ContentContainer ref={containerRef} className='youtube-content-container'>
        {title && (
          <TitleBtnWrapper className='title-btn-wrapper'>
            <ContentTitle className='video-title'>{title}</ContentTitle>
            {btnText && (
              <ViewButton
                className='view-btn desktop-btn'
                href={`https://www.youtube.com/playlist?list=${playlistId}`}
                target='_blank'>
                {btnText}
              </ViewButton>
            )}
          </TitleBtnWrapper>
        )}
        <VideosContainer
          className='youtube-videos-container'
          isShorts={isShorts}>
          {!loading
            ? videos.map((x) => {
                return (
                  <VideoWrapper
                    className='youtube-videos-wrapper'
                    key={x?.snippet?.resourceId?.videoId}
                    isShorts={isShorts}>
                    <IframeVideoWrapper
                      isShorts={isShorts}
                      className='iframe-wrapper'
                      src={`https://www.youtube-nocookie.com/embed/${x?.snippet?.resourceId?.videoId}`}
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                      frameborder='0'
                      allowFullScreen></IframeVideoWrapper>
                    {!isShorts && (
                      <VideoTitle className='video-title'>
                        {x?.snippet?.title}
                      </VideoTitle>
                    )}
                  </VideoWrapper>
                );
              })
            : Array(videosLimit)
                .fill(null)
                .map((_, index) => (
                  <PlaceholderImage
                    key={`skeleton-${index}`}
                    className={`video-skeleton-wrapper ${!isShorts && 'mobile-only-one'}`}
                  />
                ))}
        </VideosContainer>
        {btnText && (
          <ViewButton
            className='view-btn mobile-btn'
            href={`https://www.youtube.com/playlist?list=${playlistId}`}
            target='_blank'>
            {btnText}
          </ViewButton>
        )}
      </ContentContainer>
    )
  );
};

YoutubePlaylist.propTypes = {
  isShorts: PropTypes.bool,
  playlistId: PropTypes.string,
  videosLimit: PropTypes.number,
  title: PropTypes.string,
  btnText: PropTypes.string,
};

YoutubePlaylist.defaultProps = {
  isShorts: false,
  endpointType: 'playlist',
  playlistId: '',
  videosLimit: 3,
  title: '',
  btnText: '',
};

export default YoutubePlaylist;
