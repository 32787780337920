import {
  HeroBannerV1,
  heroBannerConfigV1,
  HeroBannerV2,
  heroBannerConfigV2,
} from './hero-banner';
import Button, { buttonConfig } from './button';
import Card, { cardConfig } from './card';
import Carousel, { carouselConfig } from './carousel';
import Columns, { columnsConfig } from './columns';
import FAQSectionRef, { faqSectionRefConfig } from './faq-section-ref';

import BlogArticleRef, { blogArticleRefConfig } from './blog-articles-ref';
import BlogNavigation, { BlogNavigationConfig } from './blog-navigation';
import BlogArticlesFilteredRef, { BlogArticlesFilteredConfig } from './blog-articles-filtered';
import BlogHeroRef, { BlogHeroConfig } from './blog-hero';
import BlogRecentPosts, { BlogRecentPostsConfig } from './blog-recent-posts';


import HubspotForm, { hubspotFormConfig } from './hubspot-form';
import Modal, { modalConfig } from './modal';
import QuoteCard, { quoteCardConfig } from './quote-card';
import ROICalcualtor, { roiCalculatorConfig } from './roi-calculator';
import Section, { sectionConfig } from './section';
import StaggeredCard, { staggeredCardConfig } from './staggered-card';
import Text, { textConfig } from './text';
import Video, { videoConfig } from './video';
import Artboard, { artboardConfig } from './artboard';
import ArtboardOptions, { artboardOptionsConfig } from './artboard-options';
import RetailMap, { retailMapConfig } from './retail-map';
import Accordion, { accordionConfig } from './accordion';
import ExploreMaterialsSecondary, { exploreMaterialsConfig } from './explore-materials';
import ExploreFeatures, { exploreFeaturesConfig } from './explore-features';
import FeatureCarousel, { featureCarouselConfig } from './feature-carousel';
import YoutubePlaylist, { youtubePlaylistsConfig } from './youtube-playlist';
// These are selected so we can decrease our bundle size.
import '@builder.io/react/dist/lib/src/blocks/Columns';
import '@builder.io/react/dist/lib/src/blocks/CustomCode';
import '@builder.io/react/dist/lib/src/blocks/Embed';
import '@builder.io/react/dist/lib/src/blocks/Fragment';
import '@builder.io/react/dist/lib/src/blocks/Image';
import '@builder.io/react/dist/lib/src/blocks/Section';
import '@builder.io/react/dist/lib/src/blocks/Slot';
import '@builder.io/react/dist/lib/src/blocks/Symbol';
import '@builder.io/react/dist/lib/src/blocks/Text';
/**
 * Helper to register all builder components.
 * If you'd like a subset, or to register them differently,
 * you should registerComponents in a different place and not
 * use this function.
 */
export default function registerComponents(Builder, components) {
  Builder.registerComponent(Button, buttonConfig);
  Builder.registerComponent(Card, cardConfig);
  Builder.registerComponent(Carousel, carouselConfig);
  Builder.registerComponent(Columns, columnsConfig);
  Builder.registerComponent(FAQSectionRef, faqSectionRefConfig);
  Builder.registerComponent(BlogArticleRef, blogArticleRefConfig);
  Builder.registerComponent(BlogArticlesFilteredRef, BlogArticlesFilteredConfig);
  Builder.registerComponent(BlogNavigation, BlogNavigationConfig);
  Builder.registerComponent(BlogHeroRef, BlogHeroConfig);
  Builder.registerComponent(BlogRecentPosts, BlogRecentPostsConfig);


  Builder.registerComponent(Accordion, accordionConfig);
  Builder.registerComponent(HeroBannerV1, heroBannerConfigV1);
  Builder.registerComponent(HeroBannerV2, heroBannerConfigV2);
  Builder.registerComponent(HubspotForm, hubspotFormConfig);
  Builder.registerComponent(Modal, modalConfig);
  Builder.registerComponent(QuoteCard, quoteCardConfig);
  Builder.registerComponent(ROICalcualtor, roiCalculatorConfig);
  Builder.registerComponent(Section, sectionConfig);
  Builder.registerComponent(StaggeredCard, staggeredCardConfig);
  Builder.registerComponent(Text, textConfig);
  Builder.registerComponent(Video, videoConfig);
  Builder.registerComponent(Artboard, artboardConfig);
  Builder.registerComponent(ArtboardOptions, artboardOptionsConfig);
  Builder.registerComponent(RetailMap, retailMapConfig);
  Builder.registerComponent(FeatureCarousel, featureCarouselConfig);
  Builder.registerComponent(ExploreMaterialsSecondary, exploreMaterialsConfig);
  Builder.registerComponent(ExploreFeatures, exploreFeaturesConfig);
  Builder.registerComponent(YoutubePlaylist, youtubePlaylistsConfig);

  components?.forEach(({ config, component }) => Builder.registerComponent(component, config));

  const componentNames = components?.map(({ config }) => ({ name: config.name })) ?? [];

  Builder.register('insertMenu', {
    name: 'Glowforge Components',
    items: [
      { name: buttonConfig.name },
      { name: cardConfig.name },
      { name: carouselConfig.name },
      { name: columnsConfig.name },
      { name: faqSectionRefConfig.name },
      { name: accordionConfig.name },
      { name: blogArticleRefConfig.name },
      { name: BlogArticlesFilteredConfig.name },
      { name: BlogNavigationConfig.name },
      { name: BlogHeroConfig.name },
      { name: BlogRecentPostsConfig.name },
      { name: heroBannerConfigV2.friendlyName },
      { name: hubspotFormConfig.name },
      { name: modalConfig.name },
      { name: quoteCardConfig.name },
      { name: sectionConfig.name },
      { name: staggeredCardConfig.name },
      { name: textConfig.name },
      { name: roiCalculatorConfig.name },
      { name: videoConfig.name },
      { name: artboardConfig.name },
      { name: artboardOptionsConfig.name },
      { name: retailMapConfig.name },
      { name: featureCarouselConfig.name },
      { name: exploreMaterialsConfig.name },
      { name: exploreFeaturesConfig.name },
      { name: youtubePlaylistsConfig.name },
      ...componentNames,
    ],
  });

  Builder.register('insertMenu', {
    name: 'Builder Components',
    items: [
      { name: 'Image' },
      { name: 'Box' },
      { name: 'Custom Code' },
      { name: 'Embed' },
      { name: 'Slot' },
    ],
  });
}
