import { withChildren, BuilderBlocks } from '@builder.io/react/lite';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle, useTheme } from 'styled-components';
import css from '@styled-system/css';

import { addIdAttribute } from '../util/shared-configs';

const SectionWrapper = createGlobalStyle`
    section[builder-id='${(props) => props.$builderId}']{
      background-color: ${(props) => props.$backgroundColor};
    }
`;

/**
 * Glowforge:Section component.
 * The built-in builder.io Section has some not-great defaults
 */
export function SectionComponent({
  attributes,
  builderBlock,
  dataTestId,
  maxWidth,
  backgroundColor,
  lazyLoad,
}) {
  const theme = useTheme();

  const sectionStyle = {};
  sectionStyle.backgroundColor =
    backgroundColor && typeof backgroundColor === 'string'
      ? css({
          backgroundColor,
        })(theme)?.backgroundColor ?? undefined
      : undefined;

  const [state, setState] = useState({ inView: !lazyLoad });

  const ref = useRef(null);
  useEffect(() => {
    if (lazyLoad) {
      if (typeof IntersectionObserver === 'undefined' || !ref) {
        setState({ inView: true });
      } else {
        const observer = new IntersectionObserver((entries, innerObserver) => {
          entries.forEach((entry) => {
            if (entry.intersectionRatio > 0) {
              setState({
                inView: true,
              });
              if (ref) {
                innerObserver.unobserve(ref.current);
              }
            }
          });
        });

        observer.observe(ref.current);

        return () => {
          observer.disconnect();
        };
      }
    }
    return undefined;
  }, [ref, lazyLoad]);

  const renderContents = lazyLoad ? state.inView : true;
  
  return (
    <>
      <SectionWrapper
        $builderId={builderBlock.id}
        $backgroundColor={sectionStyle.backgroundColor}
      />
      <section {...attributes} ref={ref} {...addIdAttribute(dataTestId)}>
        {renderContents ? (
          <BuilderBlocks
            style={{
              width:
                maxWidth && typeof maxWidth === 'number' ? maxWidth : undefined,
              maxWidth: '100%',
              margin: '0 auto',
            }}
            child
            parentElementId={builderBlock?.id}
            dataPath='this.children'
            blocks={builderBlock.children}
          />
        ) : null}
      </section>
    </>
  );
}

SectionComponent.propTypes = {
  attributes: PropTypes.shape({
    'builder-id': PropTypes.string.isRequired,
  }),
  backgroundColor: PropTypes.string,
  builderBlock: PropTypes.shape({
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.any),
      PropTypes.elementType,
    ]),
    id: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
  dataTestId: PropTypes.string,
  lazyLoad: PropTypes.bool,
  maxWidth: PropTypes.number,
};

SectionComponent.defaultProps = {
  attributes: {},
  backgroundColor: null,
  children: null,
  dataTestId: null,
  lazyLoad: false,
  maxWidth: null,
};

export default withChildren(SectionComponent);
